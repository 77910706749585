import React from 'react'
import Label from '../../../Label'
import Button from '../../../Buttons'

import mockupProject from '../../../../assets/img/mockup-project.png'
import { Link } from 'react-router-dom'

const LastProject = () => {
  return (
    <section className='container last-project border-radius-portfolio py-5'>
      <h2 className='text-uppercase text-center'>Mon dernier projet</h2>
      <div className="container d-flex flex-column flex-lg-row justify-content-between align-items-center p-5">
        <div className='last-project-img pe-lg-5'>
          <img src={mockupProject} alt=""/>
        </div>
        <div className='text-start ps-lg-5 pt-5 pt-lg-0'>
          <h3>SIS.33</h3>
          <div className="d-flex pb-4">
            <Label text="wordpress" className="me-2" />
            <Label text="JS" className="me-2" />
            <Label text="HTML/CSS" />
          </div>
          <p className='pb-5'>
            SIS 33 formation est un organisme de formation et de conseil dans le domaine de la Santé et de la Sécurité au Travail qui accompagne les TPE et PME afin de leur simplifier leurs démarches prévention et de formation.
            <br />J'ai réalisé le design complet, ainsi que le développement du site sous WordPress à l'aide du plugin Elementor.
          </p>
          <Link to="/portfolio"><Button text="voir les projets"  className="mx-auto mx-lg-0" /></Link>
        </div>
      </div>
      <div className="container d-none d-lg-flex align-items-center justify-content-between text-center pt-5">
        <div>
          <h4>Date</h4><hr />
          <p>Sept. 2022</p><hr />
        </div>
        <div>
          <h4>Nature</h4><hr />
          <p>Projet professionnel</p><hr />
        </div>
        <div>
          <h4>Theme</h4><hr />
          <p>Maquette design + WordPress</p><hr />
        </div>
        <div>
          <h4>URL</h4><hr />
          <a href="http://www.formation-sis33.fr" target="_blank" className='link-text'>formation-sis33</a><hr />
        </div>
      </div>
      <div className="container d-flex d-lg-none align-items-center justify-content-around text-center pt-5">
        <div>
          <div>
            <h4>Date</h4><hr />
            <p>Sept. 2022</p><hr />
          </div>
          <div className='pt-5'>
            <h4>Nature</h4><hr />
            <p>Projet professionnel</p><hr />
          </div>
        </div>
        <div>
          <div>
            <h4>Theme</h4><hr />
            <p>Maquette design + WordPress</p><hr />
          </div>
          <div className='pt-5'>
            <h4>URL</h4><hr />
            <a href="http://www.formation-sis33.fr" target="_blank" className='link-text'>formation-sis33</a><hr />
          </div>
        </div>
      </div>
    </section>
  )
}

export default LastProject