export const QuestionsList = [
    {
        fournisseur: "Florian Buisson",
        sujet: "Questions concernant les tarifs",
        questions: [
            {
                id: 0,
                question: "Quel est le prix moyen ?",
                answer: "Le prix d’un projet varie en fonction de sa complexité, de mon implication au sein d’une équipe, etc.<br />Le prix payé est en moyenne de <span>3500€ HT</span> pour un site vitrine de 5 pages."
            },
            {
                id: 1,
                question: "Y'a-t-il des frais supplémentaires à prévoir ?",
                answer: "Dans le cadre d'un projet web, il est essentiel de savoir qu'un tel projet nécessite sa mise en ligne sur un <span>serveur qui hébergera</span> l'intégralité de ses fichiers. Il peut arriver que certains projets utilisent des <span>plugins parfois payants</span> (de façon mensuelle, annuelle, etc.), et qui sont parfois indispensables.<br /><span>Un nom de domaine</span>, utilisé par les utilisateurs/clients est également à prévoir pour permettre l'accès au projet en ligne."
            },
            {
                id: 2,
                question: "Comment gérer la maintenance et la sécurité ?",
                answer: "La sécurité et la maintenance de votre projet web sont des tâches inévitables au fonctionnement à long terme de celui-ci. C'est pour cette raison que chaque projet web est associé à un <span>forfait mensuel basic de 20€ HT</span>. Celui-ci comprends la gestion de l'hébergement et du nom de domaine, l'optimisation, les mises à jour et sauvegardes backup."
            },
            {
                id: 3,
                question: "Est-ce que tout m'appartient une fois le projet terminé ?",
                answer: "Oui, absolument tout ce qui a été construit <span>vous appartient à 100%</span>. La plupart de mes collaborations continue de prospérervia la maintenance du site internet. Mais si pour une raison ou une autre, vous souhaitez héberger votre site ailleurs, ou faire appel à un autre service de maintenance en ligne, je vous accompagnerai dans la transition aussi efficacement que possible."
            }
        ]
    }
];
