import React from 'react'

import Button from '../../Buttons'
import LastProject from './LastProject'


import temple_large from '../../../assets/img/japan_temple2.png'
import me from '../../../assets/img/me.jpg'
import line from  '../../../assets/svg/sprites/line.svg'
import cssIcon from '../../../assets/svg/icons/css-icon.svg'
import htmlIcon from '../../../assets/svg/icons/html-icon.svg'
import reactIcon from '../../../assets/svg/icons/react-icon.svg'
import reduxIcon from '../../../assets/svg/icons/redux-icon.svg'
import firebaseIcon from '../../../assets/svg/icons/firebase-icon.svg'
import wordpressIcon from '../../../assets/svg/icons/wordpress-icon.svg'
import photoshopIcon from '../../../assets/svg/icons/photoshop-icon.svg'
import javascriptIcon from '../../../assets/svg/icons/javascript-icon.svg'
import { Link } from 'react-router-dom'

const Home = () => {
  return (
    <>
        <section className='hero-container d-flex justify-content-center text-uppercase text-center'>
            <img src={temple_large} alt="" className='' />
            <div className="hero-text">
                <p className="display">Ensemble</p>
                <svg className='curved-line' height="18" viewBox="0 0 688 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 14.5C3 14.5 209.81 2.92959 344.5 3.0002C478.412 3.0704 685 14.5 685 14.5" stroke="#FEF6F6" strokeWidth="5" strokeLinecap="round"/>
                </svg>
                <h1 className='pt-3'>fabriquons un site web qui vous ressemble.</h1>
            </div>
        </section>
        <section className='container d-flex justify-content-center justify-content-lg-between flex-wrap'>
            <img src={me} alt="Florian Buisson UI Designer & Front-end developer" className='border-radius-portfolio' />
            <div className='text-lg-start text-center mt-5 mt-lg-0'>
                <h2>Florian Buisson</h2>
                <h3>Développeur Front-End / UI Designer</h3>
                <p className='auto-width pt-3'>Je vous accompagne dans la création d’un site internet qui réponds à vos exigences, tout en vous apportant une structure stable sur laquelle continuer vos futurs projets. Mon intérêt est davantage portée sur l’implantation web de jeunes entreprises.</p>
                <Link to="/Contact" ><Button text="me contacter" icon={false} className="mx-auto mx-lg-0 mt-5" /></Link>
            </div>
        </section>
        <section className="container icon-list d-flex d-md-flex justify-content-around align-items-center">
            <a href="https://firebase.google.com" target="_blank" rel="noreferrer"><img className="skills-icon" src={firebaseIcon} alt="" /></a>
            <img src={line} alt="" className='d-none d-md-flex' />
            <a href="https://developer.mozilla.org/fr/docs/Web/HTML" target="_blank" rel="noreferrer" className='d-none d-md-flex'><img className="skills-icon " src={htmlIcon} alt="" /></a>
            <img src={line} alt=""  className='d-none d-md-flex' />
            <a href="https://developer.mozilla.org/fr/docs/Web/CSS" target="_blank" rel="noreferrer" className='d-none d-md-flex' ><img className="skills-icon" src={cssIcon} alt="" /></a>
            <img src={line} alt=""  className='d-none d-md-flex' />
            <a href="https://developer.mozilla.org/fr/docs/Web/JavaScript" target="_blank" rel="noreferrer" className='d-none d-md-flex' ><img className="skills-icon" src={javascriptIcon} alt="" /></a>
            <img src={line} alt="" />
            <a href="https://www.adobe.com/fr/products/photoshop.html" target="_blank" rel="noreferrer"><img className="skills-icon" src={photoshopIcon} alt="" /></a>
            <img src={line} alt="" />
            <a href="https://fr.reactjs.org" target="_blank" rel="noreferrer"><img className="skills-icon" src={reactIcon} alt="" /></a>
            <img src={line} alt="" />
            <a href="https://redux.js.org" target="_blank" rel="noreferrer"><img className="skills-icon" src={reduxIcon} alt="" /></a>
            <img src={line} alt="" />
            <a href="https://wordpress.com/fr/" target="_blank" rel="noreferrer"><img className="skills-icon" src={wordpressIcon} alt="" /></a>
        </section>
        <LastProject />
        <section className="container">
            <h2 className='text-uppercase text-center'>un prix juste pour un travail précis</h2>
            <div className='card-portfolio border-radius-portfolio text-uppercase text-center mt-5 p-5'>
                <h3>Taux journalier</h3>
                <p className='subtitle'>(UI Design, Développement Web, Intégration, ...)</p>
                <h4 className='display card-price pt-5 pb-3'><span>€</span>350<span>/Jour</span></h4>
                <Link to="/Tarifs"><Button text="Voir les tarifs détaillés" className="mx-auto mt-5" /></Link>
                
                <p className='caption mt-5'>Mes tarifs sont basés sur mes compétences et peuvent varier selon la complexité du projet.</p>
            </div>
        </section>
    </>
  )
}

export default Home