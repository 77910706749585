import React, { Component } from 'react'
import { QuestionsList } from './QuestionsList'

import Button from '../../Buttons'
import CtaBanner from '../../CtaBanner'


const INITIAL_STATE = {
  storedQuestions: [],
  question: null,
  answer: null,
  idQuestion: 0,
  maxQuestion: 4,
}


class Tarifs extends Component {


    constructor(props) {
        super(props)
        this.state = INITIAL_STATE
        this.refButtons = []
    }

    addClassButton = (id) => {
        this.refButtons.map((button) =>
            button.classList.remove('active')
        )

        this.refButtons[id].classList.add('active')
    }

    loadQuestions = () => {
        const fetchedArrayQuestions = QuestionsList[0].questions
        const newArray = fetchedArrayQuestions.map( ({...data}) => data)
        this.setState({
            storedQuestions: newArray
        });
    }

    nextQuestion = () => {
        if(this.state.idQuestion !== this.state.maxQuestion - 1) {
            this.setState(prevState => ({
                idQuestion: prevState.idQuestion + 1
            }))
            this.addClassButton(this.state.idQuestion + 1)
        }
    }

    previousQuestion = () => {
        if(this.state.idQuestion > 0) {
            this.setState(prevState => ({
                idQuestion: prevState.idQuestion - 1
            }))
            this.addClassButton(this.state.idQuestion - 1)
        }
    }

    selectQuestion = (questionNumber) => {
        if(this.state.idQuestion !== questionNumber) {
            this.setState ({
                idQuestion: questionNumber
            })
            this.addClassButton(questionNumber)
        }
    }

    componentDidMount() {
        this.loadQuestions()
        this.addClassButton(this.state.idQuestion)
    }

    componentDidUpdate(prevProps, prevState) {

        if((this.state.storedQuestions !== prevState.storedQuestions) && this.state.storedQuestions.length) {
            this.setState({
                question: this.state.storedQuestions[this.state.idQuestion].question,
                answer: this.state.storedQuestions[this.state.idQuestion].answer,
            })
        }

        if((this.state.idQuestion !== prevState.idQuestion) && this.state.storedQuestions.length) {
            this.setState({
                question: this.state.storedQuestions[this.state.idQuestion].question,
                answer: this.state.storedQuestions[this.state.idQuestion].answer,
            })
        }
    }

    
    render () {
        
        const {
            question,
            answer
        } = this.state

        
        return (
            <main>
                <section className='container text-center'>
                    <h1 className=''>TARIFS</h1>
                    <h2>Un prix juste pour un travail précis.</h2>
                    <p className="caption">Mes tarifs font l’objet d’une étude sérieuse de mes capacités et de mon expériences, et sont le reflet de ce que j’apporte à chaque projet.</p>
                    <div className="card-portfolio border-radius-portfolio mt-5 p-5">
                        <div className="row">
                            <div className="col-6">
                                <h3>UI DESIGN</h3>
                                <p className="caption">(création de design ou redesign)</p>
                            </div>
                            <div className="col-6">
                                <h3>FRONT-END</h3>
                                <p className="caption">(développement d’un design existant)</p>
                            </div>
                        </div>
                        <div className="row">
                            <h4 className='display card-price pt-5 pb-3'><span>€</span>350<span>/Jour</span></h4>
                        </div>
                    </div>
                </section>
                <section className='text-center'>
                    <hr className="dashed my-5" />
                    <h2>Bon à savoir</h2>
                    <div className="container d-flex justify-content-center py-5">
                        <div className="col-2 d-none d-lg-flex align-items-center">
                            <a role="button"  className='mx-auto' onClick={() => this.previousQuestion()}>
                                <Button className="arrow-left mx-auto" iconSelect={"arrow"} />
                            </a>
                        </div>
                        <div className="col-lg-8 card-portfolio question-portfolio border-radius-portfolio p-5">
                            <div className='question-text'>
                                <h3>{ question }</h3>
                                <p dangerouslySetInnerHTML={ {__html: answer} } className="question-answer pt-3"></p>
                            </div>
                            <div className="question-dots d-flex d-column justify-content-center mt-5">
                                <a role="button"  className='mx-auto d-lg-none' onClick={() => this.previousQuestion()}>
                                    <Button className="arrow-left mx-auto" iconSelect={"arrow"} />
                                </a>
                                <div>
                                    <button ref={ref => (this.refButtons[0] = ref)} onClick={() => this.selectQuestion(0)} className=''/>
                                    <button ref={ref => (this.refButtons[1] = ref)} onClick={() => this.selectQuestion(1)} className='' />
                                    <button ref={ref => (this.refButtons[2] = ref)} onClick={() => this.selectQuestion(2)} className='' />
                                    <button ref={ref => (this.refButtons[3] = ref)} onClick={() => this.selectQuestion(3)} className=''/>
                                </div>
                                <a role="button"  className='mx-auto d-lg-none' onClick={() => this.nextQuestion()}>
                                    <Button className="arrow mx-auto" iconSelect={"arrow"} />
                                </a>
                            </div>
                        </div>
                        <div className="col-2 d-none d-lg-flex align-items-center">
                            <a role="button"  className='mx-auto' onClick={() => this.nextQuestion()}>
                                <Button className="arrow mx-auto" iconSelect={"arrow"} />
                            </a>
                        </div>
                    </div>
                    <hr className="dashed my-5" />
                </section>
                <section className='container'>
                    <CtaBanner />
                </section>
            </main>
        )
    }
}

export default Tarifs