import React from 'react'

const Label = props => {

  const  {text, className} = props
  return (
    <span className={`label-portfolio text-uppercase border-radius-portfolio ${className}`}>{ text }</span>
  )
}

export default Label