import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../Buttons'

const CtaBanner = () => {
  return (
    <div className='cta-banner container row justify-content-between border-radius-portfolio text-center text-lg-start mx-auto p-5'>
        <div className="col-12 col-lg-6">
            <h3>Envie de travailler avec moi ?</h3>
            <p>Envoie moi un message via ma page de contact, j’y réponds sous 48h !</p>
        </div>
        <div className="col-12 col-lg-3 d-flex justify-content-center align-items-center pt-5 pt-lg-0">
            <Link to="/contact"><Button text={"contact"} iconSelect={"envelop"} /></Link>
        </div>
    </div>
  )
}

export default CtaBanner