import {useLayoutEffect} from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'

import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/pages/Home';
import Tarifs from './components/pages/Tarifs';
import Contact from './components/pages/Contact';
import Portfolio from './components/pages/Portfolio';


const Wrapper = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
} 

function App() {

  return (
    <Router>
      <Wrapper>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/Tarifs" element={<Tarifs />} />
          <Route exact path="/Contact" element={<Contact />} />
          <Route exact path="/Portfolio" element={<Portfolio />} />
        </Routes>
        <Footer />
      </Wrapper>
    </Router>
  );
}

export default App;
