import React, { useState } from 'react'
import Button from '../../Buttons'
import { ProjectsList } from './ProjectsList'

const Portfolio = () => {

  const [selectProject, setSelectProject] = useState(null)
  

  const e = selectProject !== null ? 
          <>
              <section className="container">
                <div className="row mt-5">
                  <div className="col-6 col-lg-9">
                    <h1>{ selectProject.title }</h1>
                  </div>
                  <div className="col-6 col-lg-3 text-end text-lg-center">
                    <a role="button" tabindex="0" onClick={() => setSelectProject( null )} className="me-4"><Button text="retour"/></a>
                  </div>
                </div>
                <div className='project-logo border-radius-portfolio d-block d-lg-none py-4 mt-5'  style={{backgroundColor: `${selectProject.colorBg}`}}>
                      <a href={selectProject.link} target="_blank" className='d-flex justify-content-center align-items-center'><img src={selectProject.logo} alt="" /></a>
                    </div>
                <div className="row mt-5">
                  <div className="project border-radius-portfolio col-lg-9 py-4 px-4">
                    <h3>DÉTAILS DU PROJET</h3>
                    <hr className='dashed my-3 project-line' />
                    <div className="row mt-3">
                      <div className="col-4"><p>Date</p></div>
                      <div className="col-8"><p>{ selectProject.date }</p></div>
                    </div>
                    <hr className='dashed my-3 project-line' />
                    <div className="row mt-3">
                      <div className="col-4"><p>Nature</p></div>
                      <div className="col-8"><p> { selectProject.nature }</p></div>
                    </div>
                    <hr className='dashed my-3 project-line' />
                    <div className="row mt-3">
                      <div className="col-4"><p>Description</p></div>
                      <div className="col-8"><p> { selectProject.desc }</p></div>
                    </div>
                    <hr className='dashed my-3 project-line' />
                    <div className="row mt-3">
                      <div className="col-4"><p>Url</p></div>
                      <div className="col-8"><a href={selectProject.link}> { selectProject.link }</a></div>
                    </div>
                  </div>
                  <div className="project-stack col-lg-3">
                    <div className='project-logo border-radius-portfolio d-none d-lg-block'  style={{backgroundColor: `${selectProject.colorBg}`}}>
                      <a href={selectProject.link} target="_blank" className='d-flex justify-content-center align-items-center'><img src={selectProject.logo} alt="" /></a>
                    </div>
                    <div className="project border-radius-portfolio mt-3 py-4 px-4">
                      <h3>STACK TECHNIQUE</h3>
                      <hr className='dashed my-4 project-line' />
                      {
                        selectProject.stack.map( ({id, stack, image}) => {
                          return (
                            <>
                              <div key={id} className="row">
                                <div className="col-4"><img src={image} alt="stack icon" className='stack-icon'/></div>
                                <div className="col-8 d-flex align-items-center"><p>{ stack }</p></div>
                                
                              </div>
                              <hr className='dashed my-4 project-line' />
                            </>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              </section>
          </>
          :
          <>
            <section className='text-center'>
              <div className="container">
                  <h1 className=''>PORTFOLIO</h1>
                  <h2>La porte ouverte à mes projets.</h2>
                  <p className="caption">Mes projets professionnels, et personnels disponibles au même endroit.</p>
              </div>
            </section>
            <section className="container">
              <div className="row mt-5">
                <div className="col-lg-3 text-center text-lg-start">
                  <h3>CE QUE JE FAIS ?</h3>
                  <p>Des designs uniques et élaborés en fonction de vos besoins. Un travail passionné de qualité.</p>
                </div>
                <div className="col-lg-9 mt-5 mt-lg-0">
                  {
                    ProjectsList[0].projects.map(({id, image, alt, title, desc}) => {
                      return (
                        <>
                          <div key={id} className="row project-list">
                            <div className="col-lg-6">
                              <img src={image} alt={alt} className="border-radius-portfolio" />
                            </div>
                            <div className="col-lg-6 text-start px-lg-5 pt-5 pt-lg-0">
                              <h2>{ title }</h2>
                              <p className='pt-3'>{ desc }</p>
                              <a role="button" tabindex="0" onClick={() => {
                                setSelectProject( ProjectsList[0].projects[id] );
                                window.scrollTo(0, 0);
                                }}><Button text={"voir le projet"} className="mt-5"></Button></a>
                            </div>
                          </div>
                          <hr className='dashed my-5 project-line' />
                        </>
                      )
                    })
                  }
                </div>
              </div>
            </section>
          </>

  return (
    <>
      { e }
    </>
  )
}

export default Portfolio