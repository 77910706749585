export const ProjectsList = [
    {
        fournisseur: "Florian Buisson",
        sujet: "Liste de mes projets",
        projects: [
            {
                id: 0,
                title: "SIS.33",
                date: "Septembre 2022",
                nature: "Projet professionnel",
                desc: "SIS 33 formation est un organisme de formation et de conseil dans le domaine de la Santé et de la Sécurité au Travail qui accompagne les TPE et PME afin de leur simplifier leurs démarches prévention et de formation. J'ai réalisé le design complet, ainsi que le développement du site sous WordPress à l'aide du plugin Elementor.",
                link: "http://www.formation-sis33.fr",
                image: "/img/projects/Sis33/formation-sis33.png",
                alt: "projet formationsis33 Florian Buisson front-end developer",
                logo: "/img/projects/Sis33/formation-sis33-logo.png",
                colorBg: "#ef7a7a",
                stack: [
                    {id:1, stack: "WordPress", image: "/img/projects/icons/wordpress-icon.svg"},
                    {id:2, stack: "CSS", image: "/img/projects/icons/css-icon.svg"},
                    {id:3, stack: "Elementor", image: "/img/projects/icons/elementor-icon.svg"}
                ]
            },
            {
                id: 1,
                title: "ACONNECT",
                date: "Août 2022",
                nature: "Projet professionnel",
                desc: "Ajout d'une gallerie photo sur la homepage, selon la demande du client en créant un design qui correspond à la charte de couleur de l'entreprise.",
                link: "https://aconnect.ca",
                image: "/img/projects/Aconnect/aconnect.png",
                alt: "projet aconnect Florian Buisson front-end developer",
                logo: "/img/projects/Aconnect/aconnect-logo.png",
                colorBg: "#03152f",
                stack: [
                    {id:1, stack: "WordPress", image: "/img/projects/icons/wordpress-icon.svg"},
                    {id:2, stack: "CSS", image: "/img/projects/icons/css-icon.svg"},
                    {id:3, stack: "Photoshop", image: "/img/projects/icons/photoshop-icon.svg"}
                    
                ]
            },
            {
                id: 2,
                title: "TRAVEL INTO SPACE",
                date: "Juillet 2022",
                nature: "Projet personnel",
                desc: "Projet frontendmentor visant à coder le front-end d’un site internet d’information spatiale et dont beaucoup de notions différentes de code CSS sont abordées.",
                link: "",
                image: "/img/projects/TravelIntoSpace/travel-into-space.png",
                alt: "projet travel into space Florian Buisson front-end developer",
                logo: "/img/projects/TravelIntoSpace/travel-into-space-logo.png",
                colorBg: "#d0d6f9",
                stack: [
                    {id:1, stack: "NuxtJS", image: "/img/projects/icons/nuxt-icon.svg"},
                    {id:2, stack: "Photoshop", image: "/img/projects/icons/photoshop-icon.svg"}
                ]
            }
        ]
    }
];
