import React, { useState } from 'react'
import { Axios, db } from '../../../firebase/firebaseConfig'
import Button from '../../Buttons'

import letterboxBg from '../../../assets/img/letterbox-background.png'
import letterboxMobileBg from '../../../assets/img/letterbox-mobile-background.png'

const Contact = () => {


    const [formData, setFormData] = useState({})

    const updateInput = e => {
        setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        })
    }

    const handleSubmit = event => {
        event.preventDefault()
        sendEmail()
        setFormData({
        name: '',
        subject: '',
        email: '',
        message: '',
        })
    }

    const sendEmail = () => {
        Axios.post(
        'https://us-central1-my-portfolio-22001.cloudfunctions.net/submit',
        formData
        )
        .then(res => {
            db.collection('emails').add({
            name: formData.name,
            subject: formData.subject,
            email: formData.email,
            message: formData.message,
            time: new Date(),
            })
        })
        .catch(error => {
            console.log(error)
        })
    }

    return (
        <section className='text-center'>
            <div className="container">
                <h1 className=''>CONTACT</h1>
                <h2>Le premier pas vers votre projet web.</h2>
                <p className="caption">Le temps d'attente pour une réponse est généralement de 48h.</p>
            </div>
            <img src={letterboxMobileBg} alt="" className='d-block d-lg-none contact-img-mobile mt-5' />
            <div className='contact-portfolio d-flex align-items-lg-center mt-5'>
                <form id="contact-form" onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="form-group text-start col-12 col-lg-6">
                            <label htmlFor="name">Prénom</label>
                            <input type="text" className="form-control mt-2" name="name" placeholder="Indiquez votre prénom" onChange={updateInput} value={formData.name || ''} />
                        </div>
                        <div className="form-group text-start col-12 col-lg-6 mt-4 mt-lg-0">
                            <label htmlFor="subject">Sujet</label>
                            <input type="text" className="form-control mt-2" name="subject" placeholder="En quoi puis-je être utile ?" onChange={updateInput} value={formData.subject || ''} />
                        </div>
                    </div>
                    <div className="form-group text-start mt-4">
                        <label htmlFor="exampleInputEmail1">Email</label>
                        <input type="email" className="form-control mt-2" aria-describedby="emailHelp" name="email" placeholder="Indiquez votre email" onChange={updateInput} value={formData.email || ''} />
                    </div>
                    <div className="form-group text-start mt-4">
                        <label htmlFor="message">Message</label>
                        <textarea className="form-control mt-2" rows="10" name="message"placeholder="Écrire votre message" onChange={updateInput} value={formData.message || ''} ></textarea>
                    </div>
                    <Button text="Envoyer" icon={false} className="mx-auto mt-4" />
                </form>
                <img src={letterboxBg} alt="" className='d-none d-lg-block' />
            </div>
        </section>
    )
}

export default Contact